.app-layout-topbar {
  &__content {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    position: relative;
  }

  &__content {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  &__sidebar-toggle {
    display: none;
  }
  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      height: clamp(30px, 8vw, 50px);
    }
  }
  &__account {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  @media screen and (max-width: 500px) {
    &__logo {
      left: 5.5rem;
      transform: translateY(-50%);
    }
  }

  @include media-mobile {
    &__greeting {
      display: none;
    }
  }

  @include media-laptop {
    &__info {
      display: none;
    }
    &__sidebar-toggle {
      display: initial;
    }
    &__left {
      display: none;
    }
  }
}
