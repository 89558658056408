.add-user-modal {
  &__bottom-button-wrapper {
    min-width: 40%;
    display: block;

    > * {
      width: 100%;
    }
  }
}
