.register-layout {
  display: flex;
  min-height: 100vh;
  align-items: stretch;

  --register-form-width: 520px;

  &__form-wrapper {
    position: fixed;
    left: 0;
    width: var(--register-form-width);
    top: 0;
    height: 100vh;

    padding: 4rem 2rem;

    overflow-y: auto;

    display: grid;
    grid-template-rows: 1fr auto;
    grid-gap: 2em;
    height: 100%;
    overflow-y: auto;

    background-color: white;

    z-index: 150;

    > * {
      max-width: 520px;
      margin: 0 auto;
    }

    form {
      padding: 2rem 0;
      place-self: center stretch;

      h1 {
        @media screen and (max-width: 500px) {
          font-size: 1.25rem;
        }
      }
    }

    &--mobile {
      transform: translateX(-100%);
      transition: 0.2s;
      width: 100%;
    }
    &--mobile-open {
      transform: initial;
    }
  }

  &__form-space-holder {
    width: var(--register-form-width);

    &--hidden {
      width: 0px;
    }
  }
  &__send-gift-container {
    flex: 1;

    &--mobile {
      width: 100vw;
    }
  }

  &__filter-toggle {
    position: sticky;
    top: 120px;
    padding: 0 1.5rem;
    z-index: 50;
  }
}
