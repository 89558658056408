.drawer {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;

  width: 400px;
  overflow-y: auto;
  pointer-events: none;
  transform: translateX(100%);

  transition: transform 0.1s;

  background-color: white;
  padding: 2rem;

  border-left: 1px solid $grey-300;

  z-index: 200;

  &--open {
    transform: unset;
    pointer-events: initial;
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    pointer-events: none;
    z-index: 199;

    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;

    transition: opacity 0.2s;

    &--visible {
      opacity: 1;
      pointer-events: initial;
    }
  }
}

@media screen and (max-width: 500px) {
  .drawer {
    width: 100%;
  }
}
