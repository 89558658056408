.settings {
  &__responsive-grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}

@include media-tablet {
  .settings {
    &__responsive-grid-2 {
      grid-template-columns: 1fr;
    }
  }
}
