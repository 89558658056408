@mixin media-tablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin media-mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin media-laptop {
  @media (max-width: #{$laptop-width}) {
    @content;
  }
}
