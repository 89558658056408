.progress-unit {
  height: 5px;
  background-color: $grey-100;
  flex: 1;

  border-radius: $max-border-radius;

  position: relative;

  &__fill {
    position: absolute;
    height: 100%;
    width: 0px;
    left: 0;
    background-color: $success-color;

    border-radius: inherit;

    transition: width 0.2s;
  }
  &--filled &__fill {
    width: 100%;
  }
}
