.send-gift {
  &__container {
    max-width: 1440px;
    margin: 0 auto;
  }
  &__filter-toggle {
    padding: 0 1rem;

    position: sticky;
    // top: calc(var(--topbar-height) + 170px);
    top: calc(var(--topbar-height) + 125px);

    margin-bottom: -2.5rem;
    z-index: 50;
    pointer-events: none;

    button {
      pointer-events: all;
    }
  }
  &__next-button {
    position: fixed;

    --icon-size: 2.25rem;
    --padding: 1.75rem;

    &--bottom {
      right: 2.5rem;
      bottom: 2.5rem;
    }
    &--top {
      left: calc(50% - (var(--icon-size) + 2 * var(--padding)) / 2);
      top: calc(var(--topbar-height) + 2.5rem);
    }

    padding: var(--padding);

    animation: send-gift-next-button-animation 0.2s ease-in-out;

    z-index: 15;

    .button__text {
      transform: rotate(180deg);
    }
  }
}

@keyframes send-gift-next-button-animation {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  100% {
    opacity: initial;
    transform: none;
  }
}

.send-gift-choice {
  &__container {
    height: 100%;
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 3rem;
  }

  &__card {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    height: 350px;
    color: white;
    transition: 0.2s;

    &--employees {
      background-color: #f64e60;
      &:hover {
        background-color: #f64e60d0;
      }
    }

    &--clients {
      background-color: #0bb783;
      &:hover {
        background-color: #0bb783d0;
      }
    }
  }

  &__card-image {
    position: absolute;
    height: 75%;
    right: 0;
    bottom: 0;
  }
}

.send-gift-container {
  height: auto;
  min-height: 80%;
  display: flex;
  flex-direction: column;
}

.send-gift-finish {
  &__container {
    width: 720px;
    margin: 0 auto;
  }

  &__recipients-products-container {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  &__list {
    max-height: 300px;
    overflow-y: auto;
    word-break: break-all;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    gap: 0.75rem;
  }
}

.send-gift-send-method {
  &__container {
    max-width: 650px;
    margin: 0 auto;
    margin-top: 10vh;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 3rem;
  }

  &__card {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    min-height: 450px;
    color: white;
    transition: 0.2s;

    &--email {
      background-color: #8950fc;
      &:hover {
        background-color: #8950fcd0;
      }
    }

    &--mobile {
      background-color: #b49640;
      &:hover {
        background-color: #b49640d0;
      }
    }
  }

  &__card-image {
    position: absolute;
    height: 50%;
    right: 0;
    bottom: 0;
  }

  &__message-card {
    padding: 4rem;
    border-radius: 1rem;
    background-color: #fdfdfd;
    background-color: white;
  }
}

@include media-laptop {
  .send-gift-finish {
    &__container {
      width: 100%;
    }
  }
}

@include media-tablet {
  .send-gift-finish {
    &__recipients-products-container {
      grid-template-columns: 1fr;
    }
  }

  .send-gift-send-method {
    &__message-card {
      padding: 2.5rem;
    }
  }

  .send-gift-choice {
    &__container {
      margin: 0 auto;
      padding: 0 2rem;
      max-width: 340px;
    }
    &__grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
    &__card {
      // width: clamp(260px, 50%, 100%);
      width: 100%;
      place-self: center;
      height: 30vh;
      max-height: 250px;
    }
  }
}

@include media-mobile {
  .send-gift {
    &__bottom-nav-bar {
      justify-content: end;
      flex-wrap: wrap-reverse;
    }
  }
  .send-gift-choice {
    &__card {
      width: 100%;
    }
  }
  .send-gift-send-method {
    &__container {
      width: initial;
      margin: initial;
      margin-top: 10vw;
    }
    &__message-card {
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }
}

@import "tab-card";
@import "quick-send-form";
