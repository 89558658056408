.product-list {
  &__title {
    padding: 0 1.5rem;
    font-size: 1.4rem;
  }

  &__list {
    padding: 3px 1.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--product-card-width));
    justify-content: space-between;
    gap: 1.5rem 1rem;
  }
}

@include media-tablet {
  .product-list {
    &__title {
      text-align: left;
      font-size: initial;
    }
    &__list {
      overflow-x: auto;
      display: flex;
      justify-content: unset;
    }

    &--on-mobile &__list {
      @include no-scrollbar;
    }
  }
}

@include media-mobile {
  .product-list {
    &__list {
      gap: 0.5rem;
    }
  }
}
