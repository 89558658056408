.button {
  $self: #{&};

  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  line-height: 1;

  font: inherit;
  font-size: 1em;
  font-weight: 500;

  border-radius: 0.5rem;

  transition: all 0.2s, transform 0.1s;

  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  white-space: nowrap;

  position: relative;

  &__text {
    display: inline-flex;
  }

  &__icon-left {
    position: absolute;
    left: 1.5em;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-flex;

    #{$self}--sm & {
      left: 1em;
    }
  }
  &__icon-right {
    position: absolute;
    right: 1.5em;
    top: 50%;
    transform: translate(50%, -50%);
    display: inline-flex;

    #{$self}--sm & {
      right: 1em;
    }
  }

  &__loader {
    line-height: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }

  &--block {
    display: flex;
    width: 100%;
  }

  &--sm {
    min-width: 4em;
    font-size: 0.85em;
    padding: 0.5em 0.75em;
    font-weight: bold;
  }
  &--md {
    min-width: 12em;
    padding: 0.5em 1em;
  }
  &--lg {
    min-width: 16em;
    padding: 0.75em 1.5em;
    font-size: 1.1em;
  }

  &--with-icon-left,
  &--with-icon {
    padding-left: 3em;
    &#{$self}--sm {
      padding-left: 2em;
    }
  }
  &--with-icon-right,
  &--with-icon {
    padding-right: 3em;

    &#{$self}--sm {
      padding-right: 2em;
    }
    // &#{$self}--md {
    //   padding-left: 1rem;
    //   padding-right: 1rem;
    // &#{$self}--lg {
    //   padding-left: 1rem;
    //   padding-right: 1rem;
    // }
  }

  &--auto {
    min-width: initial;
  }

  &--rounded {
    border-radius: 999px;
  }
  &--sharp {
    border-radius: 0px;
  }

  // Because when the button is pressed it becomes smaller, we need an element to hold it's position when you click close to the border
  &__counter-scale {
    position: absolute;

    // Assuming border width will be 1px
    --border-width: -1px;

    left: var(--border-width);
    right: var(--border-width);
    top: var(--border-width);
    bottom: var(--border-width);

    border: inherit;

    opacity: 0;
    border-radius: inherit;
  }

  &:active:not(&--un-clickable):not(&--disabled) {
    transform: scale(0.95);

    #{$self}__counter-scale {
      transform: scale(1.05);
    }
  }

  &--loading &__text {
    opacity: 0;
  }
  &--loading &__loader {
    display: flex;
  }

  &--align-left {
    justify-content: start;
  }
  &--align-right {
    justify-content: end;
  }
  &--align-center {
    justify-content: center;
  }

  &--primary:not(&--disabled) {
    background-color: $primary-color;
    color: white;

    &:hover:not(#{$self}--un-clickable) {
      background-color: $primary-600;
    }

    &#{$self}--light {
      color: $primary-color;
      background-color: transparent;

      &:hover:not(#{$self}--un-clickable) {
        background-color: $primary-100;
      }
    }
    &#{$self}--bordered {
      color: $primary-color;
      background-color: white;
      border-color: $primary-color;

      &:hover:not(#{$self}--un-clickable) {
        background-color: $primary-100;
      }
    }
    &#{$self}--subtle {
      color: $primary-color;
      background-color: $primary-100;

      &:hover:not(#{$self}--un-clickable) {
        background-color: $primary-200;
      }
    }
  }
  &--secondary:not(&--disabled) {
    background-color: $secondary-color;
    color: white;

    &:hover:not(#{$self}--un-clickable) {
      background-color: $secondary-600;
    }

    &#{$self}--light {
      color: $secondary-color;
      background-color: transparent;

      &:hover:not(#{$self}--un-clickable) {
        background-color: $secondary-100;
      }
    }
    &#{$self}--bordered {
      color: $secondary-color;
      background-color: white;
      border-color: $secondary-color;

      &:hover:not(#{$self}--un-clickable) {
        background-color: $secondary-100;
      }
    }
    &#{$self}--subtle {
      color: $secondary-color;
      background-color: $grey-100;

      &:hover:not(#{$self}--un-clickable) {
        background-color: $grey-200;
      }
    }
  }
  &--danger:not(&--disabled) {
    background-color: $danger-color;
    color: white;

    &:hover:not(#{$self}--un-clickable) {
      background-color: $danger-600;
    }

    &#{$self}--light {
      color: $danger-color;
      background-color: transparent;

      &:hover:not(#{$self}--un-clickable) {
        background-color: $danger-100;
      }
    }
    &#{$self}--bordered {
      color: $danger-color;
      background-color: white;
      border-color: $danger-color;

      &:hover:not(#{$self}--un-clickable) {
        background-color: $danger-color;
        color: white;
      }
    }
    &#{$self}--subtle {
      color: $danger-color;
      background-color: $grey-100;

      &:hover:not(#{$self}--un-clickable) {
        background-color: $grey-200;
      }
    }
  }

  &--un-clickable {
    cursor: default;
  }

  &--disabled {
    background-color: $grey-200;
    cursor: not-allowed;
    color: $grey-800;

    &:hover {
      color: $grey-800;
    }
  }

  &:focus-visible {
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.35);
  }
}

@include media-mobile {
  .button {
    &--sm {
      font-size: 0.7em;
    }
    &--md {
      font-size: 0.8em;
    }
    &--lg {
      font-size: 1em;
    }
  }
}
