.pagination {
  font-size: 0.85em;
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: stretch;

  li:not(.selected):not(.disabled) {
    cursor: pointer;

    &:hover a {
      background-color: $secondary-200;
    }
    &:active a {
      transform: scale(0.95);
    }
  }

  li a {
    color: $secondary-color;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em 0.75em;
    border-radius: $border-radius;
    border: 1px solid $secondary-color;
    margin: 0 0.125em;
    transition: 0.2s;
    height: 100%;
    min-width: 3em;
  }

  &__move a {
    padding: 0 0.125em !important;
  }

  .selected a {
    background-color: $secondary-600;
    color: white;
  }

  .disabled a {
    background-color: $grey-300;
    border-color: $grey-300;
    svg {
      color: $grey-700;
    }
  }
}

@include media-mobile {
  .pagination {
    font-size: 0.7em;
  }
}
