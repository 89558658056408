.text-button {
  position: relative;

  cursor: pointer;
  transition: 0.2s;
  outline: none;
  border: 1px solid transparent;

  &:hover {
    background-color: $primary-100;
  }
  &:focus {
    border-color: $primary-300;
  }
}
