.horizontal-tab-panel {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 0.5rem;

  &__wrapper {
    flex: 1;
    display: flex;
    justify-content: stretch;
    position: relative;
  }

  font: inherit;
  text-align: left;
  outline: none;
  border: none;

  transition: transform 0.2s;

  padding: 1rem 3.5rem 1rem 1rem;
  border-radius: 0.5rem;

  border: 1px solid $grey-400;
  background-color: $grey-100;

  &__wrapper:first-of-type & {
    border-top-left-radius: 1.5rem;
    // border-bottom-left-radius: 1.5rem;
  }
  &__wrapper:last-of-type & {
    // border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  &__number {
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-size: 1.2rem;
    font-weight: 600;

    color: white;

    background-color: $grey-200;
  }
  &__title-container {
    flex: 1;
  }
  &__title {
    font-weight: 500;
  }

  &__next-button-wrapper {
    position: absolute;
    right: 0.65rem;
    top: 50%;
    transform: translate(-2rem, -50%);
    opacity: 0;
    pointer-events: none;

    transition: 0.2s;

    &--visible {
      opacity: initial;
      pointer-events: initial;
      transform: translateY(-50%);
    }

    .button {
      padding: 0.5rem;
    }
  }

  &--completed {
    // background-color: $grey-50;
    border-color: $grey-500;
    cursor: pointer;
  }
  &--completed &__number {
    background-color: $grey-500;
  }

  &--active {
    border-color: $primary-color;
    background-color: white;
  }

  &--active &__number {
    background-color: $primary-color;
  }
  &--active &__title {
    color: $primary-color;
  }

  &--completed:active {
    transform: scale(0.95);
  }
}

.horizontal-tab-panels-conatiner {
  &--laptop {
    .horizontal-tab-panel {
      &__subtitle {
        display: none;
      }
    }
  }
  &--tablet {
    .horizontal-tab-panel {
      flex-direction: column;
      padding: 0.5rem;

      &__number {
        width: 2.5rem;
        height: 2.5rem;
      }
      &__title {
        font-size: 0.9rem;
      }
    }
  }
  &--mobile {
    .horizontal-tab-panel {
      flex-direction: column;
      padding: 0.5rem;

      &__number {
        width: 2rem;
        height: 2rem;
        font-size: 1rem;
      }
      &__title {
        font-size: 0.7rem;
      }
    }
  }
}
