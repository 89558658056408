.horizontal-tab-panels-conatiner {
  position: sticky;
  top: var(--topbar-height);

  width: 100%;
  padding: 1rem;
  background-color: white;
  transition: box-shadow 0.4s, padding-top 0.2s;

  z-index: 50;

  &--scrolled {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  }

  &--no-topbar {
    top: 0;
  }
}
