.product-card {
  border: none;
  outline: none;
  font: inherit;
  text-align: left;

  padding: 0.25em;
  border-radius: 1.5em;

  background-color: white;

  width: var(--product-card-width);
  min-width: var(--product-card-width);

  transition: transform 0.2s, background 0.2s, box-shadow 0.2s;

  font-size: 1.5rem;

  display: flex;
  align-items: stretch;
  flex-direction: column;

  position: relative;

  &__image {
    width: 100%;
    height: var(--product-card-image-height);
    border-radius: inherit;

    img {
      width: 100%;
      height: 100%;
      border-radius: inherit;
      object-fit: cover;
    }
  }

  &__info-container {
    margin-top: 0.5em;
    padding: 0.5em;
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
  }

  &__title {
    font-weight: 400;
  }
  &__price {
    font-weight: 700;
    color: $grey-600;
    font-size: 0.9em;
    margin-bottom: 0.5em;
  }
  &__brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__brand-title {
    font-size: 0.75em;
  }
  &__brand-image {
    height: 1.5em;
    width: 3em;
    border-radius: 0.25em;

    img {
      height: 100%;
      width: 100%;

      object-fit: cover;
      border-radius: inherit;
    }
  }

  &__redeem-types {
    position: absolute;
    top: 1.25rem;
    right: 1.5rem;

    display: flex;
    align-items: stretch;

    border-radius: 0.5rem;
    padding: 0.25em;
    gap: 0.25em;

    background-color: #c9b46999;
    color: white;
    backdrop-filter: blur(8px);

    font-size: 1.2rem;
  }

  &__redeem-types-divider {
    border-left: 1px solid currentColor;
    opacity: 0.5;
  }

  &--selectable {
    cursor: pointer;
    &:active {
      transform: scale(0.95);
    }
  }
  // &--selectable:not(&--selected):hover {
  //   background-color: $secondary-100;
  // }
  &--selected {
    box-shadow: 0px 0px 0px 3px $success-500;
  }
}

@mixin product-card-size($size) {
  :root {
    --product-card-width: #{$size};
    --product-card-image-height: #{$size * 5 / 7};
  }
}

@include product-card-size(260px);

@include media-laptop {
  @include product-card-size(220px);
  .product-card {
    font-size: 1.2rem;
  }
}
@include media-tablet {
  @include product-card-size(175px);
  .product-card {
    font-size: 1rem;
  }
}

@include media-mobile {
  @include product-card-size(126px);
  .product-card {
    font-size: 0.8rem;

    &__redeem-types {
      font-size: 0.9rem;
      top: 0.75rem;
      right: 1rem;
    }

    &--selected {
      box-shadow: 0px 0px 0px 2px $success-500;
    }
  }
}
