.auth-layout {
  &__container {
    display: grid;
    grid-template-columns: 600px 1fr;
    grid-template-rows: 100vh;
  }
  &__image {
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    p {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      left: 1em;
      right: 1em;
      padding: 1em 1.5em;

      font-size: 30px;
      line-height: 36px;
      color: white;

      border-radius: 0.25em;
      background-color: #00000055;
    }
  }
  &__content-container {
    max-width: 520px;
    margin: 0 auto;
    padding: 3rem;

    display: grid;
    grid-template-rows: 1fr auto;
    grid-gap: 2em;
    height: 100%;
    overflow-y: auto;
  }
  &__content-body {
    place-self: center stretch;
  }
}

@media (max-width: 1260px) {
  .auth-layout {
    &__image {
      p {
        font-size: 20px;
        line-height: 24px;
      }
    }
    &__container {
      grid-template-columns: 400px 1fr;
    }
  }
}

@media (max-width: 770px) {
  .auth-layout {
    &__image {
      display: none;
    }
    &__container {
      grid-template-columns: 1fr;
    }
  }
}
