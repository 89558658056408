html,
body {
  font-weight: initial;
  font-size: 14px !important;
}

.ebs-card {
  overflow: initial;
  border-radius: $border-radius;
  background-color: $white;

  &__header,
  &__body,
  &__footer {
    background-color: transparent;
  }
}

.react-tel-input.ebs-input__phone {
  .form-control {
    font-size: rem(14px);
    padding: rem($select-padding-y $select-padding-x);
    padding-left: 45px;
  }

  &--small {
    .form-control {
      font-size: rem(12px);
      padding: rem($input-padding-y-sm $input-padding-x-sm);
    }
  }
  &--medium {
    .form-control {
      padding: rem($input-padding-y $input-padding-x);
    }
  }
  &--large {
    .form-control {
      padding: rem($input-padding-y-lg $input-padding-x-lg);
    }
  }
}

.ebs-icon {
  color: inherit;
  flex-shrink: 0;
}

.ebs-modal__size--small {
  max-width: 650px;
}

.upload__container {
  border: 1px solid $grey-200;
  border-radius: $border-radius;
  padding: 0.5rem 0.75rem;
  align-items: center;
  margin-top: 0;
  position: relative;
  overflow: hidden;
  background-color: white;

  &::before {
    content: "";
    position: absolute;
    left: 0px;
    height: 100%;
    width: 20%;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.7), rgba(0, 0, 0, 0));
    pointer-events: none;
    transition: 0.2s;
    opacity: 0;
    animation: none;
    display: none;
  }
  &--loading {
    background-color: $grey-200;
  }
  &--loading::before {
    display: initial;
    animation: background-shift-loading 1.2s cubic-bezier(0.53, 0.09, 0.45, 0.9) infinite alternate;
  }
}

.upload__progress__bar {
  transition: 0.2s;
}

@keyframes background-shift-loading {
  0% {
    left: -10%;
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    left: 110%;
    opacity: 0;
  }
}

.ebs-textarea {
  max-height: 200px;
  resize: vertical;
}

.ebs-layout__content-wrapper {
  min-height: calc(100vh - #{$layout-top-bar-height});
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}

.ebs-layout__container .ebs-layout__content {
  height: unset;
  overflow-x: auto;
}

.ebs-select__options-items {
  height: 200px !important;
}

.ebs-select {
  &--medium {
    min-height: calc(1.5em + #{$select-padding-y}) !important;
  }
}

.ebs-checkbox {
  width: 1.2rem;
  height: 1.2rem;
  padding: 0.2rem;
  border-radius: 4px;
  color: white;
}

.ebs-loader {
  z-index: inherit;
}

a {
  text-decoration: none;
}

// .ebs-checkbox {
//   border-radius: 3px;
//   height: 1.2rem;
//   width: 1.2rem;

//   &::before {
//     background-color: transparent !important;
//   }
// }

// .ebs-checkbox__input:checked ~ .ebs-checkbox .ebs-checkbox__check {
//   font-size: 0.9rem;
// }

// $checkbox-d-path: path("M6 18L13 24L26 10");

// @supports (d: $checkbox-d-path) {
//   .ebs-checkbox__check path {
//     d: $checkbox-d-path;
//     stroke-width: 6;
//     transform: none;
//     fill: none;
//   }
// }

// .ebs-checkbox__input:hover ~ .ebs-checkbox {
//   border-color: #b0babf;
// }

.ebs-checkbox__wrapper {
  &:hover .ebs-checkbox {
    border-color: #b0babf;
  }
  &:focus-within .ebs-checkbox {
    box-shadow: 0px 0px 0px 2px $primary-200;
    border-color: transparent;
  }
}

.ebs-layout__top-bar {
  position: relative;
}

.ebs-input,
.ebs-input__container {
  border-radius: inherit;
}

.ebs-datepicker--medium,
.ebs-textarea {
  padding: 11px $input-padding-x;
}

.ebs-datepicker:disabled,
.ebs-datepicker:disabled:hover,
.ebs-input:disabled {
  background-color: $grey-200;
}

.ebs-input__phone.disabled {
  opacity: 0.75;
  .form-control {
    border-radius: $border-radius;
  }
  .form-control,
  .flag-dropdown {
    background-color: $grey-200 !important;
    border-color: $grey-300 !important;
  }
}

.ebs-textarea__wrapper.has-error .ebs-textarea {
  border-color: $danger-color;
}
.ebs-textarea.disabled {
  background-color: $grey-200;
}

.ebs-notify {
  position: fixed;
}

.ebs-select__wrapper .ebs-tooltip__trigger {
  padding: 0;
  margin: 0;
}

.ebs-tooltip,
.ebs-tooltip__trigger {
  margin: 0;
  padding: 0;
}
