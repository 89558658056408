:root {
  --topbar-height: 70px;
}

.topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  background-color: white;
  border-bottom: 1px solid $grey-300;

  z-index: 100;

  display: flex;

  height: var(--topbar-height);
}
