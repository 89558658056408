.loader {
  $self: #{&};
  line-height: 1;
  position: relative;

  display: inline-flex;
  justify-content: center;

  --loader-size: 1.2em;
  --loader-width: 0.1em;
  --loader-padding: 1em;
  &--sm {
    --loader-size: 1.2em;
    --loader-width: 0.1em;
    --loader-padding: 1em;
  }
  &--md {
    --loader-size: 3rem;
    --loader-width: 0.25em;
    --loader-padding: 2em;
  }
  &--lg {
    --loader-size: 7rem;
    --loader-width: 0.4em;
    --loader-padding: 4em;
  }

  &:not(&--spread),
  &__circle-1,
  &__circle-2 {
    width: var(--loader-size);
    height: var(--loader-size);
    border-radius: 50%;
  }

  &--spread {
    display: flex;
    height: calc(var(--loader-size) + 2 * var(--loader-padding));
  }

  &__circle-1,
  &__circle-2 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: var(--loader-width) solid transparent;
    border-top-color: currentColor;
  }

  &__circle-1 {
    animation: loader-rotate-animation 1s ease infinite;
  }
  &__circle-2 {
    animation: loader-rotate-animation 1s linear infinite;
    border-style: dotted;
  }
}

@keyframes loader-rotate-animation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
