.product-list-group {
  padding: 0 1rem;
  @include media-laptop {
    padding: 0;
  }

  &__title {
    margin-bottom: 0.5rem;
    padding: 0 1.5rem;
    color: $grey-600;
    font-size: 1.6rem;
  }
  &__lists-container {
    padding: 2rem 0;
    // background-color: #f5f3f1;
    background-color: $grey-100;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 2rem;
  }
}
