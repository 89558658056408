.sroll-to-top-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;

  z-index: 15;

  &__icon {
    animation: scroll-to-top-button-animation 0.2s forwards;
  }
}

@keyframes scroll-to-top-button-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-180deg);
  }
}
