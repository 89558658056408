.catalogue {
  &__filter-toggle {
    padding: 0 1rem;

    position: sticky;
    top: calc(var(--topbar-height) + 1rem);

    margin-bottom: -2rem;
    z-index: 50;
    pointer-events: none;

    button {
      pointer-events: all;
    }
  }
}
