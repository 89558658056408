.table {
  $self: #{&};

  overflow: hidden;
  border-radius: 1rem;
  background-color: #fff;

  table {
    table-layout: auto;
    width: 100%;
    border-collapse: collapse;
    font-weight: normal;
  }

  &-cell {
    padding: 1rem 1.5rem;
    position: relative;

    &-ellipsis {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &-row {
    position: relative;
    border-bottom: 1px solid $border-color;
    transition: 0.1s;

    &:last-child {
      border-bottom: none;
    }
  }

  &-thead {
    text-align: left;
    border-bottom: 1px solid $primary-color;

    #{$self}-cell {
      font-weight: 500;
      padding: 1.5rem 1.5rem;
      white-space: nowrap;
    }
  }

  &-footer {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
  }

  &--has-title {
    #{$self}-thead tr {
      border-top: 1px solid $border-color;
    }
  }

  &--has-footer {
    #{$self}-row:last-child {
      border-bottom: 1px solid $border-color;
    }
  }

  &--bordered {
    border: 1px solid $border-color;

    #{$self}-cell:not(:last-child) {
      border-right: 1px solid $border-color;
    }
  }

  &--stripped {
    #{$self}-row:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.031);
    }
  }

  &--stripped-even {
    #{$self}-row:nth-of-type(even) {
      background-color: rgba(0, 0, 0, 0.031);
    }
  }

  &--black-header {
    #{$self}-thead {
      background-color: rgba(0, 0, 0, 0.75);
      color: white;
    }
  }

  &--dense {
    #{$self}-thead #{$self}-cell {
      padding: 1rem 1rem;
    }
    #{$self}-cell {
      padding: 0.5rem 1rem;
    }
  }

  &--highlight-hover {
    #{$self}-row {
      cursor: pointer;
    }
    #{$self}-row:hover {
      background-color: rgba(0, 0, 0, 0.063);
    }
  }

  &--elevated {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }
}

@include media-tablet {
  .table {
    $self: #{&};
    overflow: initial;

    box-shadow: none;
    background-color: transparent;
    border: none;

    colgroup {
      display: none;
    }

    &-thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    &-row {
      display: block;
      margin: 0.6rem 0;
      border-radius: 1rem;

      border: 1px solid $grey-200;
      background-color: white;
    }

    &-cell {
      border-bottom: 1px solid #ddd;
      display: flex;
      font-size: 0.8em;
      justify-content: space-between;
      align-items: center;
      text-align: right;
      width: 100%;
    }

    &--bordered &-cell:not(:last-child) {
      border-right: none;
    }

    &-cell::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-title);
      margin-right: 2rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    &-cell:last-child {
      border-bottom: 0;
    }

    // &-title {
    //   border-radius: 1rem;
    //   background-color: white;
    //   border: 1px solid $grey-200;
    // }

    &-footer {
      border-radius: 1rem;
      background-color: white;
      border: 1px solid $grey-200;

      justify-content: center;
    }
  }
}
