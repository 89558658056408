.page-container {
  min-height: calc(100vh - var(--topbar-height));

  &--grey {
    background-color: $grey-100;
  }
  &--white {
    background-color: white;
  }
  &--padded {
    padding: 1.5rem;
  }

  &__width-limit {
    max-width: 1420px;
    margin: 0 auto;
  }
}
