.send-gift-tab-card {
  padding: 2rem 1.5rem;
  background-color: $grey-100;
  border-radius: 1rem;

  margin: 0 1rem;
  @include media-laptop {
    margin: 0;
  }

  min-height: calc(100vh - 140px - var(--topbar-height));

  &--center-vertically {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }
}
