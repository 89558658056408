.linear-progress {
  height: 6px;
  border-radius: 3px;
  overflow: hidden;

  div {
    height: 100%;
    border-radius: 3px;
  }
}
