.checkbox {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;

  &__check-wrapper {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    user-select: none;
    cursor: inherit;

    input {
      cursor: inherit;
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      margin: 0px;
      padding: 0px;
      z-index: 1;
    }

    border-radius: 50%;

    transition: 0.2s;

    &:hover {
      background-color: $grey-100;
    }
  }

  &__check-icon {
    position: absolute;
    width: 0px;
    height: 0px;
    overflow: hidden;

    top: 50%;
    transform: translateY(-50%);

    border-color: white;

    transition: 0.2s;
  }

  &__check-background {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 3px;

    border: 2px solid $grey-300;
    background-color: transparent;

    transition: 0.2s;
  }

  &--checked &__check-background,
  &--indeterminate &__check-background {
    background-color: $success-color;
    border-color: transparent;
  }

  &--indeterminate &__check-icon {
    width: 0.8rem;
    height: 2px;

    background-color: white;

    border-radius: 2px;
  }

  &--checked &__check-icon {
    width: 12px;
    height: 6px;

    border-bottom: 2px solid white;
    border-left: 2px solid white;

    transform: translateY(-50%) translateY(-1px) rotate(-45deg);
  }

  &__text {
    flex: 1;
  }
}
