.horizontal-tabs {
  $self: &;

  &__container {
    overflow-x: auto;
    max-width: 100%;
  }

  &__panels-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.25rem;
  }
  &__content-container {
    flex: 1;
    background-color: white;
    padding: 1.5rem 2rem;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;

    // border-bottom-left-radius: $border-radius;
    // border-bottom-right-radius: $border-radius;

    border: 1px solid $border-color;

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  &__panel-number {
    $size: 2.5rem;
    width: $size;
    height: $size;
    line-height: $size;

    border-radius: $border-radius;
    text-align: center;
    font-weight: 600;
    background-color: #e5eaee;

    transition: 0.2s;
  }
  &__panel {
    flex: 1;
    padding: 1rem;
    border-radius: $border-radius;
    // border-top-left-radius: $border-radius;
    // border-top-right-radius: $border-radius;
    cursor: pointer;
    transition: 0.2s;
    background-color: white;

    border: 1px solid $border-color;

    display: flex;
    flex-direction: column;
    justify-content: center;

    * {
      font-size: 1rem;
    }

    &:hover {
      background-color: #f3f6f9;
    }
    &.active {
      background-color: white;
      border-color: $primary-color;

      #{$self}__panel-title {
        color: $primary-color;
      }
      #{$self}__panel-number {
        background-color: $primary-color;
        color: white;
      }
    }
  }
}

@media (max-width: 1300px) {
  .horizontal-tabs {
    &__panels-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.25rem;
    }
  }
}
@include media-tablet {
  .horizontal-tabs {
    $self: &;

    &__panels-container {
      flex-direction: column;
    }
    &__panel {
      margin-bottom: 0.5rem;
    }

    &__panels-container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.25rem;
    }
  }
}
