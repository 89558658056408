.sidebar-item {
  position: relative;
  display: flex;
  align-items: stretch;

  cursor: pointer;

  transition: 0.2s;

  &__icon {
    width: var(--sidebar-collapsed-width);
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.1rem;
  }

  &__text {
    flex: 1;
    padding: 1rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover,
  &--active {
    background-color: $primary-color;
    color: white;
  }

  &--secondary:hover,
  &--secondary#{&}--active {
    background-color: #5f666c;
    color: white;
  }

  a {
    font: inherit;
    color: inherit;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
}
