:root {
  --sidebar-width: 250px;
  --sidebar-collapsed-width: 50px;
}

.sidebar {
  width: var(--sidebar-width);

  border-right: 1px solid $grey-300;
  position: fixed;
  left: 0;
  bottom: 0;
  top: var(--topbar-height);
  background-color: white;
  padding: 1rem 0;

  overflow-y: auto;
  overflow-x: hidden;

  transition: width 0.2s;

  z-index: 100;

  &__space-holder {
    width: var(--sidebar-current-width);
    min-width: var(--sidebar-current-width);
  }
  &__overlay {
    opacity: 0;
    position: fixed;
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;

    background-color: rgba(0, 0, 0, 0.8);

    &--visible {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &--collapsed {
    width: calc(var(--sidebar-collapsed-width) + 1px);
  }

  &--mobile {
    top: 0px;
    width: 0px;
    z-index: 101;
    border-right: none;

    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &--mobile-open {
    width: var(--sidebar-width);
  }
}
