.avatar {
  &__wrapper {
    width: 2rem;
    height: 2rem;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.3);
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      border-radius: $border-radius;
      object-fit: cover;
    }
  }
}
