.video-link {
  border-radius: $border-radius;
  border: 1px solid $grey-200;
  padding: 0.5rem 0.75rem;
  color: $grey-700;
  outline: none;
  display: block;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background-color: $grey-100;
  }
  &:active {
    background-color: $grey-200;
  }
  &:focus {
    border-color: $grey-500;
  }
}
