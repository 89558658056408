$primary-color: #b49640;
$secondary-color: #5f666c;
$success-color: #6aa289;
$warning-color: #f5822d;
$danger-color: #dd2d00;

// Accent colors
$accent-color-1: #cc3a7c;
$accent-color-2: #a24285;
$accent-color-3: #fcbf00;

// Primary colors
$primary-100: #fbf6da;
$primary-200: #f7ecb7;
$primary-300: #e8d68e;
$primary-400: #d2ba6c;
$primary-500: $primary-color; // main one
$primary-600: #9a7b2e;
$primary-700: #816320;
$primary-800: #684b14;
$primary-900: #563b0c;

$secondary-100: #f5f6f7;
$secondary-200: #ebeef0;
$secondary-300: #cdd0d3;
$secondary-400: #a1a4a7;
$secondary-500: $secondary-color;
$secondary-600: #4c525d;
$secondary-700: #343c4e;
$secondary-800: #21293f;
$secondary-900: #131b34;

// Semantic colors
$success-100: #e9faeb;
$success-200: #d3f5dc;
$success-300: #b3e3c4;
$success-400: #93c7aa;
$success-500: $success-color;
$success-600: #4d8b75;
$success-700: #357465;
$success-800: #215d55;
$success-900: #144d4b;

// semantic warning colors
$warning-100: #fef0d4;
$warning-200: #fedcab;
$warning-300: #fcc380;
$warning-400: #f9a960;
$warning-500: $warning-color;
$warning-600: #d26220;
$warning-700: #b04616;
$warning-800: #8e2e0e;
$warning-900: #751e08;

// semantic danger colors
$danger-100: #fde3ca;
$danger-200: #fbc196;
$danger-300: #f49561;
$danger-400: #ea6b3a;
$danger-500: $danger-color;
$danger-600: #be1800;
$danger-700: #9f0800;
$danger-800: #800002;
$danger-900: #6a0009;

// accent 1 colors
$accent-1-100: #fcd8da;
$accent-1-200: #f9b2bd;
$accent-1-300: #ef88a2;
$accent-1-400: #e06792;
$accent-1-500: $accent-color-1;
$accent-1-600: #af2a73;
$accent-1-700: #921d69;
$accent-1-800: #76125d;
$accent-1-900: #610b55;

// accent 2 colors
$accent-2-100: #fadce5;
$accent-2-200: #f5bbd3;
$accent-2-300: #e392ba;
$accent-2-400: #c76ea3;
$accent-2-500: $accent-color-2;
$accent-2-600: #8b3078;
$accent-2-700: #74216b;
$accent-2-800: #5d155d;
$accent-2-900: #470c4d;

// accent 3 colors
$accent-3-100: #fef7cb;
$accent-3-200: #feed98;
$accent-3-300: #fee065;
$accent-3-400: #fdd33f;
$accent-3-500: $accent-color-3;
$accent-3-600: #d89e00;
$accent-3-700: #b58000;
$accent-3-800: #926300;
$accent-3-900: #784f00;
