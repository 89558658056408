.dashboard {
  &__stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  &__stats-card {
    border: 1px solid $primary-color;
    display: flex;
    align-items: center;
  }

  &__stats-card-icon {
    color: $primary-color;
  }

  &__invoice-table {
    width: 100%;
    thead {
      font-weight: 700;
    }
    td:last-child {
      text-align: right;
    }
  }
}

.order-invoice {
  &__recipients-products-container {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  &__list {
    max-height: 300px;
    overflow-y: auto;
    word-break: break-all;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    gap: 0.75rem;
  }
}

.monthly-invoice {
  &__content {
    max-height: 75vh;
    overflow-y: auto;
  }
}

.monthly-invoice-item {
  &__collapse-header {
    position: relative;

    cursor: pointer;
    border-radius: 3px;
    transition: 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.025);
    }
  }
  &__collapse-date {
    width: 130px;
  }
}

.dashboard-info-cards {
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 2rem;

    ul,
    ol {
      font-size: 1.1rem;
      list-style-type: none;
    }
    ol {
      counter-reset: list-counter;

      li::before {
        counter-increment: list-counter;
        content: counter(list-counter) ".";
      }
    }
    ul {
      list-style-position: inside;

      li::before {
        content: "";
        width: 0.3em;
        height: 0.3em;
        background-color: black;
        flex-shrink: 0;

        border-radius: 50%;
      }
    }
    li::before {
      display: inline-block;
      margin-right: 0.5rem !important;
      font-weight: bold;
    }
    li {
      display: flex;
      align-items: center;
      padding: 0.5rem 0;
    }

    &--with-image {
      align-items: center;
      img {
        max-width: 300px;
        width: 100%;
        flex: 1;
      }
    }
  }
  &__item-header {
    margin-bottom: 1rem;
  }
}

@include media-tablet {
  .dashboard {
    &__stats {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 1rem;
    }
  }
  .order-invoice {
    &__recipients-products-container {
      grid-template-columns: 1fr;
    }
  }
  .dashboard-info-cards {
    &__container {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
  }
}
