.badge {
  display: inline-block;
  padding: 0.1rem 0.25rem;
  background-color: $grey-100;
  color: $grey-700;
  font-size: 0.8rem;
  border-radius: $border-radius;
}

.modest-table {
  width: 100%;
  border-collapse: collapse;

  &,
  & td,
  & th {
    border: 1px solid #d9d9d9;
    padding: 0.5rem;
  }

  th {
    text-align: left;
  }
}

.form-field-input-group {
  .ebs-form__field:not(:first-child) .ebs-input__wrapper {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .ebs-form__field:not(:last-child) .ebs-input__wrapper {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    // border-right: none;
  }

  .ebs-input__container {
    border-radius: inherit;
  }
}

.input-button-group {
  .ebs-input__container {
    flex: 1;
  }

  .ebs-input__container:not(:first-child) .ebs-input__wrapper {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .ebs-input__container:not(:last-child) .ebs-input__wrapper {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .button {
    border-radius: $border-radius;
    &:not(:first-child) {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    &:not(:last-child) {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}
