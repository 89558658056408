.auto-gifts-edit {
  &__save-button-field {
    .row,
    .row .col {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
  &__save-button {
    // position: fixed;
    // right: 1.5rem;
    // bottom: 1.5rem;

    // padding: 1.5rem;
    margin-right: 1rem;
  }
  &__filter-toggle {
    padding: 0 1rem;

    position: sticky;
    top: calc(var(--topbar-height) + 1rem);

    margin-bottom: -2rem;
    z-index: 50;
    pointer-events: none;

    button {
      pointer-events: all;
    }
  }
  &__header {
    margin: 0 2.5rem;
  }
  &__form-wrapper {
    background-color: $grey-100;
    border-radius: 1rem;
    margin: 0 1rem;
    padding: 1.5rem;
  }
  &__message-card {
    padding: 4rem;
  }
  &__inner-message-card {
    padding: 2rem;
    max-width: $tablet-width;
    background-color: #fdfdff;
    margin: 0 auto;
  }
}

@include media-laptop {
  .auto-gifts-edit {
    &__header {
      margin: 1.5rem;
    }
    &__form-wrapper {
      margin: 0;
    }
  }
}

@include media-tablet {
  h1.auto-gifts-edit__header {
    font-size: 1.2rem !important;
  }
  .auto-gifts-edit {
    &__message-card {
      border: none;
      background-color: transparent;
      padding: 0;
    }
  }
}
