.check-image {
  &__wrapper {
    display: inline-flex;
    position: relative;
  }

  &__checkbox {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    pointer-events: none;
    background-color: #4cb46b;
    border-radius: $border-radius;
    border: 1px solid $border-color;

    transform: scale(1.05);
    transition: 0.2s;

    svg {
      max-width: 100%;
      max-height: 100%;
      transform: scale(0.7);
      transition: 0.25s;

      path {
        transition: 0.3s ease-in;
        stroke-dasharray: 0 20;

        stroke: #ddffe6;
        stroke-width: 2;
        stroke-linejoin: round;
      }
    }

    &--checked {
      opacity: 1;
      svg {
        transform: initial;
        path {
          stroke-dasharray: 20 0;
        }
      }
    }
  }
}
