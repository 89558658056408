.vertical-tabs {
  &__container {
    display: flex;
  }
  &__panels-container {
    width: 350px;
  }
  &__content-container {
    flex: 1;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    background-color: white;
    padding: 2rem;
  }
  &__panel {
    padding: 1rem;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    border: 1px solid $border-color;
    border-radius: $border-radius;
    background-color: white;

    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: #f3f6f9;
    }
    &.active {
      border-color: $primary-color;
      color: $primary-color;
      background-color: white;

      svg {
        color: $primary-color;
      }
    }
  }
  &__space {
    padding-left: 4rem;
  }
}

@media (max-width: 1110px) {
  .vertical-tabs {
    &__panels-container {
      width: 250px;
    }
    &__space {
      padding-left: 1rem;
    }
  }
}

@include media-tablet {
  .vertical-tabs {
    &__container {
      display: flex;
      flex-direction: column;
    }
    &__panels-container {
      width: initial;
      margin-bottom: 2rem;
    }
    &__content-container {
      flex: 1;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      background-color: white;
      padding: 2rem;
    }
  }
}
