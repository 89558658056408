.send-gift-quick-form {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;

    &--for-clients {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .ebs-form__item:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

@include media-laptop {
  .send-gift-quick-form {
    &__grid {
      &:not(&--for-clients) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
@include media-tablet {
  .send-gift-quick-form {
    &__grid {
      grid-template-columns: 1fr !important;
    }
  }
}
