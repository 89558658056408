// Text colors
$heading-color: #444041;
$text-color: #464e5f;
$text-color-secondary: #788494;
$text-color-light: #f5f3f0;

//Font
$font-family: "Rubik", sans-serif;

$border-radius: 0.5rem;
$border-color: #e5eaec;

$card-body-bg-color: #fff;
$background-content: #fff;

$box-shadow: none;
$layout-content-background: #f4f4f5;

$input-padding-y: 20px;
$input-padding-x: 14px;

$input-padding-y-sm: 12px;
$input-padding-x-sm: 8px;

$input-padding-y-lg: 20px;
$input-padding-x-lg: 16px;

$card-padding-x: 2rem !default;
$card-padding-y: 2rem !default;
$card-padding-x-sm: 1.5rem !default;
$card-padding-y-sm: 1.5rem !default;
$card-padding-x-lg: 2.5rem !default;
$card-padding-y-lg: 2.5rem !default;

$spacing-to: 0;

$sidebar-width: 250px;
$layout-top-bar-height: 70px;

$calendar-padding-y: 8px;
$calendar-padding-y-sm: 6px;
$calendar-padding-y-lg: 10px;
