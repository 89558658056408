.category-card {
  border: 1px solid $secondary-400;
  padding: 0.75rem;
  border-radius: 1rem;

  color: $secondary-400;

  display: flex;
  gap: 1rem;
  align-items: center;

  transition: 0.2s;

  cursor: pointer;

  &__image {
    width: 1.5rem;
    height: 1.5rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__text {
    flex: 1;
  }

  &:hover:not(&--active) {
    background-color: $secondary-100;
  }

  &:active {
    transform: scale(0.95);
  }

  &--active {
    background-color: $primary-color;
    border-color: transparent;
    color: white;

    &:hover {
      background-color: $primary-400;
    }
  }
}
