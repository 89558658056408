.payment-method-card {
  &__last-4::before {
    content: "**** **** ****";
  }
  &--selectable {
    &:hover {
      background-color: $grey-200;
    }
    cursor: pointer;
    transition: 0.2s;
  }
  &.active {
    background-color: rgba(85, 224, 217, 0.438);
  }
}

.payment-method-form {
  &__responsive-4-cols {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
}
@media screen and (max-width: 1110px) {
  // @include media-tablet {
  .payment-method-form {
    &__responsive-4-cols {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@include media-mobile {
  .payment-method-form {
    &__responsive-4-cols {
      grid-template-columns: 1fr;
    }
  }
  .payment-method-card {
    &__last-4::before {
      content: "****";
    }
  }
}
