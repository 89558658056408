body {
  margin: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #4e4a4b;
}

a {
  color: #3699ff;
}

hr {
  border: none;
  border-bottom: 1px solid $border-color;
  border-left: 1px solid $border-color;
}
