.nowrap {
  white-space: nowrap;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-1 {
  flex: 1;
}

.color-gray {
  color: $grey-500;
}

.color-danger {
  color: $danger-color;
}

.hidden {
  visibility: hidden;
}

.display-none {
  display: none;
}

@for $i from 1 to 16 {
  $coef: 0.25rem;

  .p-#{$i} {
    padding: $i * $coef;
  }
  .pl-#{$i} {
    padding-left: $i * $coef;
  }
  .pr-#{$i} {
    padding-right: $i * $coef;
  }
  .pt-#{$i} {
    padding-top: $i * $coef;
  }
  .pb-#{$i} {
    padding-bottom: $i * $coef;
  }
  .px-#{$i} {
    padding-left: $i * $coef;
    padding-right: $i * $coef;
  }
  .py-#{$i} {
    padding-top: $i * $coef;
    padding-bottom: $i * $coef;
  }

  .m-#{$i} {
    margin: $i * $coef;
  }
  .ml-#{$i} {
    margin-left: $i * $coef;
  }
  .mr-#{$i} {
    margin-right: $i * $coef;
  }
  .mt-#{$i} {
    margin-top: $i * $coef;
  }
  .mb-#{$i} {
    margin-bottom: $i * $coef;
  }
  .mx-#{$i} {
    margin-left: $i * $coef;
    margin-right: $i * $coef;
  }
  .my-#{$i} {
    margin-top: $i * $coef;
    margin-bottom: $i * $coef;
  }
}

.modal-z-index-2 {
  z-index: 10000;
}

@mixin no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.no-scrollbar::-webkit-scrollbar {
  @include no-scrollbar();
}

.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}

.placeholder-center {
  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
  }

  :-ms-input-placeholder {
    text-align: center;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}
